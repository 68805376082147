import React from 'react';
import { getVersionNumber } from '../utilities/GetVersionNumber';

function FooterBar() {
  let versionNumber = getVersionNumber();

  if (versionNumber) {
    versionNumber = `v${versionNumber}`;
  }

  return <div className='footer-container'>© ChampionX {versionNumber}</div>;
}

export default FooterBar;
