import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/storeHooks';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../navigation/components/Breadcrumb';
import UISettings from '../../common/UIButtons/UISettings';
import UIButtonToolbar from '../../navigation/components/UIButtonToolbar';
import './Asset.css';
import ControlActions, {
  WellControlAction,
  ItemType,
  ActionsToExclude,
} from '../../common/ControlActions/ControlActions';
import { fetchWellControlActions, fetchEnabledStatus } from '../AssetDataSlice';
import { useAppSelector } from '../../../hooks/storeHooks';
import Tooltip from '../../common/tooltip/ToolTip';
import { setActiveChartKey } from './../../../features/asset-analysis/TrendGroupLabel/TrendGroupLabelSlice';

interface UrlParams {
  assetId: string;
  assetGroupName: string;
}

function Asset() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { assetId, assetGroupName } = useParams<keyof UrlParams>() as UrlParams;
  const currentURLPath = location.pathname.split('/')[2];
  const wellControlActionTypes = useAppSelector((state) => state.assetData.wellControlActions);
  const isWellEnabled = useAppSelector((state) => state.assetData.wellEnabledStatus) ?? false;
  const canConfigWell = useAppSelector((state) => state.assetData.canConfigWell) ?? false;
  const wellStatusData = useAppSelector((state) => state.rodliftwellstatus.data);
  const actionsToExclude: ActionsToExclude[] = [
    {
      itemType: ItemType.WellControlAction,
      controlActionType: WellControlAction.ClearAlarms,
    },
    {
      itemType: ItemType.WellControlAction,
      controlActionType: WellControlAction.EnablePid,
    },
  ];

  useEffect(() => {
    dispatch(fetchWellControlActions(assetId));
    dispatch(fetchEnabledStatus(assetId));
  }, [assetId]);

  const smartenUrlName = 'Live View';
  const navButtons = [
    {
      name: 'Status',
      active: currentURLPath === 'status',
      id: 0,
    },
    {
      name: 'Analysis',
      active: currentURLPath === 'analysis',
      id: 1,
    },
    ...(wellStatusData?.smartenLiveUrl
      ? [
          {
            name: smartenUrlName,
            id: 3,
            active: false,
          },
        ]
      : []),
  ];

  function onNavChange(navButtonId: number) {
    const navButton = navButtons.find((navButton) => navButton.id == navButtonId);
    if (navButton) {
      if (navButton.name === smartenUrlName) {
        window.open(`${wellStatusData?.smartenLiveUrl}`, '_blank');
        return;
      }
      navigate(`/asset/${navButton.name.toLowerCase()}/${assetId}/${encodeURIComponent(assetGroupName)}`);
    }
    dispatch(setActiveChartKey(1));
  }

  const [isActive, setIsActive] = useState(false);

  return (
    <div className='flex h-full flex-column'>
      <div className='flex flex-row flex-grow asset-nav-bar'>
        {assetId && (
          <div style={{ fontSize: 'large', flexGrow: 1 }}>
            <Breadcrumb />
          </div>
        )}
        <div className='trend-group-button-container ui'>
          <div data-testid='card-viewer-settings' className='trend-group-button' style={{ paddingRight: '0px' }}>
            <Tooltip content='Well control' direction='left'>
              <ControlActions
                assetId={assetId}
                label='Well control options'
                isWellEnabled={isWellEnabled}
                canConfigWell={canConfigWell}
                supportedControlActionIds={wellControlActionTypes.map((x) => x.id)}
                buttonContent={
                  <div
                    data-testid='control-actions-button'
                    className='asset-card-viewer-button'
                    onClick={() => {
                      setIsActive(!isActive);
                    }}
                  >
                    <UISettings />
                  </div>
                }
                actionsToExclude={actionsToExclude}
                modeOptionViewPosition='right'
              />
            </Tooltip>
          </div>
        </div>
      </div>
      {assetId ? (
        <div className='flex h-full flex-column'>
          <div className='asset-ui-buttons-container'>
            <UIButtonToolbar uiButtons={navButtons} buttonClicked={onNavChange} />
          </div>
          <Outlet />
        </div>
      ) : (
        <div>Bad Route</div>
      )}
    </div>
  );
}

export default Asset;
