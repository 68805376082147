import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../../onboarding/OnboardingShell.scss';
import { getAllyConnectURL } from '../../../utilities/BaseURLUtility';

import AuthService from '../../../services/authentication/auth.service';
import './Login.scss';

import Loader from '../Loader/Loader';
import { useLoginURL } from '../../../hooks/useSettingUrls';

const LoginRefToken = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get('ref');
  const loginURL = useLoginURL();

  useEffect(() => {
    if (!loginURL) return;

    AuthService.isUserLoggedIn(ref).then((isLoggedIn) => {
      if (isLoggedIn === true) {
        navigate('/');
      } else {
        const host = window.location.host;

        window.location.replace(getAllyConnectURL(loginURL) + `/Account/Login?app=${host}`);
      }
    });
  }, [ref, loginURL]);

  return <Loader />;
};

export default LoginRefToken;
