interface LocalStorageConstant {
  accessToken: string;
  refreshToken: string;
  user: string;
  userAddresses: string;
  rememberedCredentials: string;
  expiresIn: string;
  correlationId: string;
  isAdmin: string;
}

const AUTH_STORAGE_KEY = process.env.REACT_APP_AUTH_STORAGE_KEY || '';

const LocalStorageConstant: LocalStorageConstant = {
  accessToken: `${AUTH_STORAGE_KEY}access-token`,
  refreshToken: `${AUTH_STORAGE_KEY}refresh-token`,
  user: `${AUTH_STORAGE_KEY}user`,
  userAddresses: `${AUTH_STORAGE_KEY}user-addresses`,
  rememberedCredentials: `${AUTH_STORAGE_KEY}rememberedCredentials`,
  expiresIn: `${AUTH_STORAGE_KEY}expiresIn`,
  correlationId: `${AUTH_STORAGE_KEY}correlationId`,
  isAdmin: `${AUTH_STORAGE_KEY}isAdmin`,
};

export default LocalStorageConstant;
