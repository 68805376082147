import LocalStorageConstant from '../../constants/localstroage.constant';

const getLocalExpiresIn = (): string => {
  return localStorage.getItem(LocalStorageConstant.expiresIn) ?? '';
};

const getLocalRefreshToken = (): string => {
  return localStorage.getItem(LocalStorageConstant.refreshToken) ?? '';
};

const getLocalAccessToken = (): string => {
  return localStorage.getItem(LocalStorageConstant.accessToken) ?? '';
};

const getCorrelationId = (): string => {
  return localStorage.getItem(LocalStorageConstant.correlationId) ?? '';
};

const addOrUpdateLocalExpiresIn = (token: string) => {
  localStorage.setItem(LocalStorageConstant.expiresIn, token);
};

const addOrUpdateLocalAccessToken = (token: string) => {
  localStorage.setItem(LocalStorageConstant.accessToken, token);
};

const addOrUpdateLocalRefreshToken = (token: string) => {
  localStorage.setItem(LocalStorageConstant.refreshToken, token);
};
const addOrUpdateLocalCorrelationId = (correlationId: string) => {
  localStorage.setItem(LocalStorageConstant.correlationId, correlationId);
};

const getUser: any = () => {
  const user: string | null = localStorage.getItem(LocalStorageConstant.user);
  return user;
};

const setUser = (user: any) => {
  localStorage.setItem(LocalStorageConstant.user, JSON.stringify(user) ?? '');
};

const getIsAdmin = (): boolean => {
  const isAdmin = localStorage.getItem(LocalStorageConstant.isAdmin);
  return isAdmin ? JSON.parse(isAdmin) : false;
};

const addOrUpdateIsAdmin = (isAdmin: boolean | undefined) => {
  isAdmin !== undefined
    ? localStorage.setItem(LocalStorageConstant.isAdmin, JSON.stringify(isAdmin))
    : localStorage.removeItem(LocalStorageConstant.isAdmin);
};

const removeUser = () => {
  localStorage.removeItem(LocalStorageConstant.userAddresses);
  localStorage.removeItem(LocalStorageConstant.user);
  localStorage.removeItem(LocalStorageConstant.accessToken);
  localStorage.removeItem(LocalStorageConstant.refreshToken);
  localStorage.removeItem(LocalStorageConstant.expiresIn);
  localStorage.removeItem(LocalStorageConstant.correlationId);
  localStorage.removeItem(LocalStorageConstant.isAdmin);
};

const TokenService = {
  getLocalExpiresIn,
  getLocalRefreshToken,
  getLocalAccessToken,
  addOrUpdateLocalExpiresIn,
  addOrUpdateLocalAccessToken,
  addOrUpdateLocalRefreshToken,
  addOrUpdateLocalCorrelationId,
  getCorrelationId,
  getUser,
  setUser,
  getIsAdmin,
  addOrUpdateIsAdmin,
  removeUser,
};

export default TokenService;
