import { useEffect, useState } from 'react';
import { fetchSettingUrlsAsync, fetchLoginURLAsync } from '../services/settingsURL/settingUrls.service';

let cachedUrls: {
  allyOnboardingURL: string | null;
  pumpCheckerURL: string | null;
} | null = null;

let cachedLoginURL: string | null = null;

const useSettingUrls = () => {
  const [allyOnboardingURL, setAllyOnboardingURL] = useState<string | null>(null);
  const [pumpCheckerURL, setPumpCheckerURL] = useState<string | null>(null);

  useEffect(() => {
    const getUrls = async () => {
      if (cachedUrls) {
        setAllyOnboardingURL(cachedUrls.allyOnboardingURL);
        setPumpCheckerURL(cachedUrls.pumpCheckerURL);
      } else {
        const urls = await fetchSettingUrlsAsync();
        if (urls) {
          cachedUrls = {
            allyOnboardingURL: urls.values.AllyOnboardingUIURL
              ? `${urls.values.AllyOnboardingUIURL}/asset-details`
              : null,
            pumpCheckerURL: urls.values.PumpCheckerURL ? `${urls.values.PumpCheckerURL}/ESPDiagnosis` : null,
          };

          setAllyOnboardingURL(cachedUrls.allyOnboardingURL);
          setPumpCheckerURL(cachedUrls.pumpCheckerURL);
        }
      }
    };

    getUrls();
  }, []);

  return { allyOnboardingURL, pumpCheckerURL };
};

const useLoginURL = () => {
  const [loginURL, setLoginURL] = useState<string | null>(null);

  useEffect(() => {
    const getLoginURL = async () => {
      if (cachedLoginURL) {
        setLoginURL(cachedLoginURL);
      } else {
        const url = await fetchLoginURLAsync();
        if (url) {
          cachedLoginURL = url.values.LoginURL ?? null;
          setLoginURL(cachedLoginURL);
        }
      }
    };

    getLoginURL();
  }, []);
  return loginURL;
};

export { useSettingUrls, useLoginURL };
